import React, { useEffect } from 'react';
import loadable from '@loadable/component';

import { intlShape, injectIntl } from 'react-intl';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import SectionCustomColumn from '../PageBuilder/SectionBuilder/SectionCustomColumn/SectionCustomColumn';
import SectionCustomHeroOld from '../PageBuilder/SectionBuilder/SectionCustomHero/SectionCustomHeroOld';
import SectionCustomHero from '../PageBuilder/SectionBuilder/SectionCustomHero/SectionCustomHero';

import hero from '../../assets/heroNew.jpg';
import heroLR from '../../assets/heroLR.jpg';
import heroOld from '../../assets/heroOld.png';
import { landingPageHeroSection } from '../../config/configLayout';

import { GoogleAnalyticsHandler } from '../../analytics/handlers';
import { googleAnalyticsEnabled } from '../../config/configAnalytics';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

const imagePlaceholder = (width, height) => ({
  id: 'image',
  type: 'imageAsset',
  attributes: {
    variants: {
      square1x: {
        url: banner,
        width,
        height,
      },
      square2x: {
        url: banner,
        width: 2 * width,
        height: 2 * width,
      },
    },
  },
});

export const LandingPageComponent = props => {
  const { inProgress, error, intl } = props;

  useEffect(() => {
    const analyticsHandler = new GoogleAnalyticsHandler();
    if (googleAnalyticsEnabled == true) {
      analyticsHandler.trackCustomEvent('Visit');
    }
  }, []);

  const pageAssetsData =
    landingPageHeroSection.variantType == 'old'
      ? {
          sections: [
            {
              sectionType: 'hero-old',
              sectionId: 'hero-section',
              svgImage: heroOld,
            },
            {
              sectionType: 'custom-column',
              sectionId: 'custom-column-1',
              title: intl.formatMessage({ id: 'LandingPage.title' }),
              text: intl.formatMessage({ id: 'LandingPage.text' }),
              blocks: [
                {
                  blockType: 'defaultBlock',
                  blockId: 'button-1',
                  callToAction: {
                    fieldType: 'internalButtonLink',
                    href: '/shop',
                    content: intl.formatMessage({ id: 'LandingPage.shopButton' }),
                  },
                },
                {
                  blockType: 'defaultBlock',
                  blockId: 'button-2',
                  callToAction: {
                    fieldType: 'externalButtonLink',
                    href: '/sell',
                    content: intl.formatMessage({ id: 'LandingPage.sellButton' }),
                  },
                },
              ],
            },
            {
              sectionType: 'columns',
              sectionId: 'two-columns-section',
              backgroundColor: '#AFB989',
              numColumns: 2,
              blocks: [
                {
                  blockType: 'defaultBlock',
                  blockId: 'column-1',
                  title: {
                    fieldType: 'heading3',
                    content: intl.formatMessage({ id: 'LandingPage.infoSection1Title' }),
                  },
                  text: {
                    fieldType: 'paragraph',
                    content: intl.formatMessage({ id: 'LandingPage.infoSection1Text' }),
                  },
                },
                {
                  blockType: 'defaultBlock',
                  blockId: 'column-2',
                  media: {
                    fieldType: 'video',
                    content:
                      'https://cdn.shopify.com/videos/c/o/v/340d56312a2d494f97bf82567a6e625d.mp4',
                  },
                },
              ],
            },
            {
              sectionType: 'columns',
              sectionId: 'one-column-section',
              numColumns: 1,
              blocks: [
                {
                  blockType: 'defaultBlock',
                  blockId: 'column-1',
                  title: {
                    fieldType: 'heading3',
                    content: intl.formatMessage({ id: 'LandingPage.infoSection2Title' }),
                  },
                  text: {
                    fieldType: 'paragraph',
                    content: intl.formatMessage({ id: 'LandingPage.infoSection2Text' }),
                  },
                },
              ],
            },
            {
              sectionType: 'columns',
              sectionId: 'two-columns-section-2',
              backgroundColor: '#AFB989',
              numColumns: 2,
              blocks: [
                {
                  blockType: 'defaultBlock',
                  blockId: 'column-1',
                  title: {
                    fieldType: 'heading3',
                    content: intl.formatMessage({ id: 'LandingPage.infoSection3Title' }),
                  },
                  text: {
                    fieldType: 'paragraph',
                    content: intl.formatMessage({ id: 'LandingPage.infoSection3Text' }),
                  },
                },
                {
                  blockType: 'defaultBlock',
                  blockId: 'column-2',
                  media: {
                    fieldType: 'video',
                    content:
                      'https://cdn.shopify.com/videos/c/o/v/aa0e500b0dcb407ba3ee8dcdb340b7eb.mp4',
                  },
                },
              ],
            },
          ],
        }
      : {
          sections: [
            {
              sectionType: 'hero',
              sectionId: 'hero-section',
              svgImage: hero,
              svgImageLR: heroLR,
              title: intl.formatMessage({ id: 'LandingPage.title' }),
              text: intl.formatMessage({ id: 'LandingPage.text' }),
              blocks: [
                {
                  blockType: 'defaultBlock',
                  blockId: 'button-1',
                  callToAction: {
                    fieldType: 'internalButtonLink',
                    href: '/shop',
                    content: intl.formatMessage({ id: 'LandingPage.shopButton' }),
                  },
                },
                {
                  blockType: 'defaultBlock',
                  blockId: 'button-2',
                  callToAction: {
                    fieldType: 'internalButtonLink',
                    href: '/sell',
                    content: intl.formatMessage({ id: 'LandingPage.sellButton' }),
                  },
                },
              ],
            },
          ],
        };

  return (
    <PageBuilder
      pageAssetsData={pageAssetsData}
      options={{
        sectionComponents: {
          'custom-column': { component: SectionCustomColumn },
          'hero-old': { component: SectionCustomHeroOld },
          hero: { component: SectionCustomHero },
        },
      }}
      inProgress={inProgress}
      error={error}
      fallbackPage={<FallbackPage error={error} />}
      currentPage={'LandingPage'}
    />
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default injectIntl(LandingPage);
