import React from 'react';
import PropTypes from 'prop-types';
import css from './SectionCustomHeroOld.module.css';

const SectionCustomHero = ({ sectionId, svgImage }) => {
  return (
    <div className={css['section-custom-hero-container']}>
      <div className={css['section-custom-hero']}>
        <img src={svgImage} alt="Hero" className={css['section-custom-hero-image']} />
      </div>
    </div>
  );
};

SectionCustomHero.propTypes = {
  sectionId: PropTypes.string.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  svgImage: PropTypes.string.isRequired,
};

export default SectionCustomHero;
